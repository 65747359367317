@use '@/styles/utils/mixins.scss' as *;

.ctaBannerContainer {
  scroll-margin-top: 7.5rem;
}

.ctaBannerWrapper {
  margin-top: 7.5rem;
  display: flex;
  flex-direction: row;

  gap: 6.75rem;

  @include tab-des() {
    margin-top: 5.125rem;
  }

  @include tab-des() {
    flex-direction: column;
  }
  .ctaBannerContent {
    display: flex;
    flex-direction: column;
    gap: 6.25rem;
    width: 100%;
    justify-content: space-between;
    @include tab-des() {
      flex-direction: column;
      align-items: center;
    }
  }
  .ctaBannerTitle {
    display: flex;
    flex-direction: column;
    color: $primary;
    span {
      color: $dark;
    }
    @include tab-des() {
      text-align: center;
      align-items: center;
    }
  }
  .description {
    text-align: left;
    width: 100%;

    @include tab-des() {
      text-align: center;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 500;
      margin-bottom: 0.625rem;

      &:not(:first-child) {
        margin-top: 2.5rem;
      }
    }
    h1 {
      font-size: 6rem;
    }
    h2 {
      font-size: 4.5rem;
    }
    h3 {
      font-size: 3rem;
    }
    h5 {
      font-size: 2.125rem;
    }
    h6 {
      font-size: 1.5rem;
    }
    p {
      margin-bottom: 1rem;
    }
    ul,
    ol {
      padding-left: 1.5rem;
      margin-bottom: 1rem;
    }
    a {
      color: $primary;
      &:hover,
      &:focus,
      &:active {
        color: $primaryAlt;
      }
    }
  }
  .bannerImageContainer {
    flex: 0 0 auto;
    overflow: hidden;

    @include mob() {
      width: calc(100% + 3.75rem);
      margin: 0 -1.875rem;
    }
  }
  .bannerImageWrapper {
    display: flex;
    justify-content: center;
    gap: 2.5rem;
  }
  .bannerBigImageContainer {
    width: 14rem;
    flex: 0 0 auto;
  }
  .bannerSmallImageContainer {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    width: 25.875rem;
    flex: 0 0 auto;
  }

  .bannerImage {
    border-radius: 1.25rem;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
